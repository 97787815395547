/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [
    'selectorTemplate',
    'currenciesGrid',
    'currencyItem',
    'mainCurrency',
    'addCurrencyButton',
    'currenciesWidget',
    'enableCurrenciesButton'
  ]

  static values = {
    activeCurrencies: Array,
    maxCurrencies: Number
  }

  connect() {
    this.activeCurrenciesValueChanged()
    const url = new URL(window.location.href)
    if (url.searchParams.get('show_localized_currencies_widget') === 'true') {
      this.showCurrenciesWidget()
    }
  }

  activeCurrenciesValueChanged = debounce(() => {
    this.syncVisibleCurrencies(this.element)
  }, 100)

  addCurrency() {
    const clone = this.selectorTemplateTarget.content.cloneNode(true)
    this.currenciesGridTarget.appendChild(clone)
    window.requestAnimationFrame(() => {
      const latestItem = this.currencyItemTargets[this.currencyItemTargets.length - 1]
      this.syncVisibleCurrencies(latestItem)
      this.toggleAddCurrencyButton()
      this.openCurrencyItem(latestItem)
    })
  }

  syncVisibleCurrencies(rootElement) {
    rootElement.querySelectorAll('li.\\!hidden').forEach((li) => {
      this.makeListItemAvailable(li)
    })
    this.activeCurrenciesValue.forEach((currency) => {
      const options = rootElement.querySelectorAll(`input[type="checkbox"][value="${currency}"]:not(:checked)`)
      options.forEach((option) => {
        this.makeListItemUnavailable(option.closest('li'))
      })
    })
  }

  makeListItemUnavailable(item) {
    const itemController = this.application.getControllerForElementAndIdentifier(item, 'beam--combobox-list-item')
    itemController.makeUnavailable()
  }

  makeListItemAvailable(item) {
    const itemController = this.application.getControllerForElementAndIdentifier(item, 'beam--combobox-list-item')
    itemController.makeAvailable()
  }

  removeCurrency(e) {
    e.currentTarget.parentElement.remove()
    window.requestAnimationFrame(() => {
      this.onCurrencyChange()
      this.toggleAddCurrencyButton()
      if (this.currencyItemTargets.length === 0) {
        this.hideCurrenciesWidget()
      }
    })
  }

  toggleAddCurrencyButton() {
    this.addCurrencyButtonTarget.classList.toggle('hidden', this.currencyItemTargets.length >= this.maxCurrenciesValue)
  }

  onCurrencyChange = debounce(() => {
    const newCurrencies = []
    this.currencyItemTargets.forEach((currencyItem) => {
      const currency = currencyItem.querySelector('input[type="checkbox"]:checked')?.value
      if (currency) {
        newCurrencies.push(currency)
      }
    })

    const mainCurrency = this.mainCurrencyTarget.querySelector('input[type="checkbox"]:checked')?.value
    if (mainCurrency) {
      newCurrencies.push(mainCurrency)
    }

    this.activeCurrenciesValue = newCurrencies
  }, 100)

  enableCurrenciesWidget(e) {
    if (this.hasCurrenciesWidgetTarget) {
      this.showCurrenciesWidget()
    } else {
      document.getElementById("localized-pricing-gide-modal").showModal()
    }
  }

  showCurrenciesWidget() {
    this.currenciesWidgetTarget.classList.remove('hidden')
    this.enableCurrenciesButtonTarget.classList.add('hidden')
    if (this.currencyItemTargets.length === 0) {
      this.addCurrency()
    } else {
      window.requestAnimationFrame(() => {
        this.openCurrencyItem(this.currencyItemTargets[0])
      })
    }
  }

  hideCurrenciesWidget() {
    this.currenciesWidgetTarget.classList.add('hidden')
    this.enableCurrenciesButtonTarget.classList.remove('hidden')
  }

  openCurrencyItem(item) {
    const dropdown = item.querySelector('[data-controller="beam--dropdown"]')
    const dropdownController = this.application.getControllerForElementAndIdentifier(dropdown, 'beam--dropdown')
    dropdownController.open()
  }
}
